import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { IntroProject, Intro, IntroImage } from '../../../components/Intro/Intro';
import { Section, Container, CSSGrid, Col } from '../../../components/Grid';
import { CardContainer, CardBody, CardWithImageLink } from '../../../components/Card';
import { ButtonOutbound, ButtonGhost } from '../../../components/Button';
import { designTokens } from '../../../components/Theme/designTokens';
import { OutboundLink } from '../../../components/StyledLink';
import { CallToAction } from '../../../components/CallToAction';
import { Text } from '../../../components/Text';
import { IconBox, IconCard } from '../../../components/IconBox';
import { Box, ProjectBox } from '../../../components/Box';
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdHammer from 'react-ionicons/lib/MdHammer';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Playa" mdxType="SEO" />
    <IntroProject projectName="Playa" projectDescription="Lightweight wireframing with SCSS and BEM" projectYear="2016" projectImage="playa-preview" mdxType="IntroProject" />
    <Section section="Overview" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <h5>Overview</h5>
          <IconCard cardTitle="Specifications" cardText="A quick Web Design/Development project to help build atomic elements for future projects" mdxType="IconCard">
          <MdPerson fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdPerson" />
          </IconCard>
          <IconCard cardTitle="Tools" cardText="HTML, CSS, JS, Sketch" mdxType="IconCard">
            <MdHammer fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdHammer" />
          </IconCard>
          <p>I like to try and build the things I design for the web - and I like to stay as organized as I can. However, I found myself designing/coding things from scratch each time I wanted to prototype a new project - rather I wanted to focus on iterating quickly and focusing on solving problems.</p>
          <p><strong>So I created Playa.</strong></p>
          <p>Playa is a super-lightweight, open-source, configurable SCSS library that was designed for easily wireframing interfaces. Playa is built on top of SCSS and uses BEM to organize the modular components - helping designers/developers design things quickly while still keeping their work organized.</p>
          <p>
            <small>
              While other CSS Libraries such as Tailwind CSS, Bulma, and Bootstrap help designers prototype quickly as well, they can sometimes be large file sizes for what is needed in a simple project - so I aimed at creating something more lightweight and flexible for my own style of work.
            </small>
          </p>
          <ProjectBox image="playa-example-icon" title="Playa" description="View the documentation and the source code" link="https://grapalab.com/playa/" mdxType="ProjectBox" />
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "35%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9ElEQVQoz4WRS2/DIBCE8/9/Vs89pFJ7qSolqUOQLCUFB4wxj+kuOIhDHyuNLXk+xrvsLueM/+onpv+UO2bHj3fp8XLQEOdPDOcLYoyktCkiEbNKCbXf4ygktJ6QKCAUjvyUSLmElsBRB4irg1I33L4U5nlucs4hEhMnDScErkrDGEOeg7UWlpllKT8ogbXTtKkWm00plw5KaDfuIyB3TA0kIIxvuJ+e8XESGIYBIYQmHonrqEc8HV4haeSFOmJvXauYya1D7igQsFhMd9PG4VFn0koHGfQxwCyOfFt89sqbRvbed3dYkuumfttw/gvYruCx5W9xEiRF9tlrSAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Playa features",
          "title": "Playa features",
          "src": "/static/9d2620bf9d8db157d210ed6451d9ce94/d9199/overview-1.png",
          "srcSet": ["/static/9d2620bf9d8db157d210ed6451d9ce94/8ff5a/overview-1.png 240w", "/static/9d2620bf9d8db157d210ed6451d9ce94/e85cb/overview-1.png 480w", "/static/9d2620bf9d8db157d210ed6451d9ce94/d9199/overview-1.png 960w", "/static/9d2620bf9d8db157d210ed6451d9ce94/07a9c/overview-1.png 1440w", "/static/9d2620bf9d8db157d210ed6451d9ce94/29007/overview-1.png 1600w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Introduction" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <h5>First off, what is BEM?</h5>
          <p>BEM is a CSS class naming convention, allowing to write styles in a modular and maintainable way. Playa adheres to this convention so that designers and developers are able to keep organized code and prevent having to write hacky overrides. The acronym stands for:</p>
          <CSSGrid mdxType="CSSGrid">
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <Text center bold color={({
                      theme
                    }) => theme.green} mdxType="Text">Block</Text>
              </CardBody>
            </CardContainer>
          </Col>
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <Text center bold color={({
                      theme
                    }) => theme.green} mdxType="Text">Element</Text>
              </CardBody>
            </CardContainer>
          </Col>
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <Text center bold color={({
                      theme
                    }) => theme.green} mdxType="Text">Modifier</Text>
              </CardBody>
            </CardContainer>
          </Col>
        </CSSGrid>
        <p>Blocks are standalone, reusable items, Elements are children items of the Block, and Modifiers are variants that can be used on a Block.</p>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "43.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABYElEQVQoz42ROU7DQBiFfT9ocgIaEB1ISBRwAZpINFQsEkIIwRGg4wRJIIvjeF9m8RLH8ZLyMbaVxIEgKJ5+zbz5v3n/jOTPCizF47LmoNMCpl9AYwXGXg6F5JjQHAYvYAXrc3XdlNRcsGmOJCvw2oux23axf0Nx/sJx+sRx/MDQunRxcEugswxhUoLz7cClUQMXeOuG2LkYYO9KxtmjipN7BUd3Y7TaIxxeqyJpugJ+h0rrcTcNJsZxgwyyRWGwFI4YdWR7kB0PvDqz+DthWUk4F0nTKq0fR/BIFzxyBSQRb+pW8sIINJqveprgH0DV5tAcjpHOoZsd2NozbPsdfX2ID62HvvGJoTUU0HhrSql5S5AsoJgMg4kD2SAwrQCWQuFOKDydQjUYxsLXnAAmif6X0CBhJTbN4IuHZ3wmgESMnYqx6+bSq/zfgE1omTIUWn0OT+CZPliY1nuN5m2f8gWmYJfLmfX9ZgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "BEM CSS",
          "title": "BEM CSS",
          "src": "/static/78086dd0cc11ebd6e2cd61c46b073775/d9199/bem-1.png",
          "srcSet": ["/static/78086dd0cc11ebd6e2cd61c46b073775/8ff5a/bem-1.png 240w", "/static/78086dd0cc11ebd6e2cd61c46b073775/e85cb/bem-1.png 480w", "/static/78086dd0cc11ebd6e2cd61c46b073775/d9199/bem-1.png 960w", "/static/78086dd0cc11ebd6e2cd61c46b073775/07a9c/bem-1.png 1440w", "/static/78086dd0cc11ebd6e2cd61c46b073775/29007/bem-1.png 1600w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Components" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <h5>What's included</h5>
          <p>To maintain flexibilty and a small file size, I only ventured to include components that would help me move forward the most:</p>
          <ul>
            <li>12-column grid</li>
            <li>Headers, paragraphs, and text</li>
            <li>Buttons</li>
            <li>Tables</li>
            <li>Lists</li>
            <li>Forms</li>
            <li>Color utilities</li>
            <li>Aligment Utilities</li>
          </ul>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC1ElEQVQ4y4VUXU8TQRTlb+mT+mCCL8YEfVDBYBEShGpAH7RFTKOGaARj/EiAoIaYYAwgCMEHPqNgS0AoNqIUKLS7224X2u5ut9vdLXOcHVvSCsZJbu7M5t4zZ86c2TKV11AcCpeGJujIxLJ0nWHrVFhhkYlqLFQ+zb4X6mkmf+YaKSsGsiMtZCCuS+BWBdZoSiaQApAEgt8T4IOpkvp8/+GAMqdSBllMjk3i1eteVDucaHG34cXTHgz2DaH92RxqmmfhnYnQjXTIkfT/GdqAE2MT2NzaRl//CJ4878Kt1ge4cvU6ap3tcHkG8GtxC7qYLWb5b4ZZ0cTY0CckkgkUBiGAYZgQ4yJW/AtYX16HHjNoj1oAzQOm/wKMqDDiFkYGRiFKcagq/aYo0HUdlmUx8GQyhfmZBbaxXa/GGFNSYHoA0JRyGOkfBSdw8K8EwPF8niVhWVZkeKe9MGidHNWR/Bk9yLCgBQPcyWHo/TAF4hCOcIzZ3t4ecrkcA7UsE74v80hx1A332vD5yFEkgxJRKXgJoJ1tr4FKN/zuI2JijDGyrBwDNU2T6WgP35wP/JoEld48/3YQipAhal7LkiOrEQ2SuIuunm7E43GYFMjWz2ZXYGmPxYVFbAci0GVAo/60tSvRkNmFz2JjM4QPwWG4Olzgwjzu3O9A4427aGhyo7HJBWezGy2eR/i2tIyA7wd0ajF5W4aybxvKsGAXQ7CwtOaHe7oVlberoCQU1N/0oLzCgZOnq3C8/CyO0Thx6hzGp2bwdcrLHMFuuhgwT5dqoJFkWCZvpnqJ03ONdL7sJNU19eRMxQVy/qKDVF6qJZcdDaSuzkketj0ms+NzJM1niLS5S6j2h9+yQp+SzhlIhFJYmQ0g5A+BX+UhUGuIQRHSxg4SWwnE6VtPhVXGTs6bu/jIJE+ZhcyrRBU0Qv1IdNEg9K9DtKgdOkkLdmTYvLhn/5Q0/wZ9CS9xOSVSiQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Documentation Example",
          "title": "Documentation Example",
          "src": "/static/6dbf6db6c0ba233cb8a374177d405985/d9199/example-1.png",
          "srcSet": ["/static/6dbf6db6c0ba233cb8a374177d405985/8ff5a/example-1.png 240w", "/static/6dbf6db6c0ba233cb8a374177d405985/e85cb/example-1.png 480w", "/static/6dbf6db6c0ba233cb8a374177d405985/d9199/example-1.png 960w", "/static/6dbf6db6c0ba233cb8a374177d405985/07a9c/example-1.png 1440w", "/static/6dbf6db6c0ba233cb8a374177d405985/29007/example-1.png 1600w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      